<template>
  <div>
    <span class="tag-text">{{ strArr[0] }}</span>
    <span class="tag-id">ID:{{ strArr[1] }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default() {
        return ''
      }
    }
  },
  computed: {
    strArr() {
      let arr = ['', '']
      try {
        arr = this.value.split('ID:')
      } catch (error) {}
      return arr
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-id {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}
.tag-text {
  font-weight: 700;
}
</style>
