<template>
  <i
    :class="`el-icon-${status}`"
    :style="{ color: colors[status], fontSize: '20px', marginRight: '10px' }"
  ></i>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default() {
        return 'success'
      }
    }
  },
  data() {
    return {
      colors: {
        success: '#67C23A',
        warning: '#E6A23C',
        error: '#F56C6C',
        delete: 'red'
      }
    }
  }
}
</script>
